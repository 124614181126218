.container {
  margin: 1rem;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 15px;
}

.card {
  width: 18rem;
  background: rgba(255, 255, 255, 0.8);
  border: none;
}

.cardBody {
  text-align: center;
}

.cardTitle {
  margin-top: 10px;
}

.title {
    text-align: center;
    margin-top: 3vh;
    margin-bottom: 3vh;
}