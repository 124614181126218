/* Contenido.css */
.background-image {
    background-image: url('../img/banner.png'); /* Ruta de la imagen de fondo */
    background-size: cover; /* Escala la imagen para abarcar todo el elemento */
    background-repeat: no-repeat; /* Evita la repetición de la imagen */
    background-position: center; /* Centra la imagen en el elemento */
    min-height: 100vh; /* Asegura que la imagen ocupe al menos toda la altura de la pantalla */
    display: flex;
    align-items: center;
    
  }
  