
.nav {
  background: rgb(36, 36, 35);
  display: flex;
  justify-content: space-between; /* Cambio aquí para separar el logo y las rutas */
  align-items: center; /* Centra verticalmente el contenido */
  padding: 10px 0; /* Añade un poco de espacio en la parte superior e inferior */
}

.cont-lg {
  max-width: 90%;
  width: 992px;
  margin: 0 auto;
  display: flex; /* Agregamos display flex para alinear los elementos */
  align-items: center; /* Centra verticalmente el contenido */
}

.logo img {
  width: 80px; /* Ajusta el ancho según tu preferencia */
  height: auto; /* Ajusta la altura de forma proporcional al ancho */
  margin-right: 20px; /* Ajusta el margen derecho para separar el logo de los elementos de navegación */
}
  
  .hamburger {
    display: none; 
    font-size: 24px;
    cursor: pointer;
  }
  
  .nav-list {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  
  .nav-list li {
    margin-right: 20px;
  }
  
  .nav-list a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 25px 25px;
    transition: .3s ease all;
    font-size: 18px;
  }
  .nav img {
    width: 80px; /* Ajusta el ancho según tu preferencia */
    height: auto; /* Ajusta la altura de forma proporcional al ancho */
    margin-right: 20px; /* Ajusta el margen derecho para separar el logo de los elementos de navegación */
}
  
  
.nav a:hover {
    background: rgb(202, 150, 52);
}
  
  @media screen and (max-width: 768px) {
    .nav{
        padding: 10px;
    }
    .hamburger {
      display: block;
      border: none;
      background: transparent;
      right: 20px;
      position: fixed;
      top: 10px;
      cursor: pointer;
    }
    
    
  
    .nav-list {
      flex-direction: column; 
      position: absolute;
      top: 60px; 
      left: -100%; 
      background: orange;
      width: 100%;
      transition: left 0.3s ease-in-out;
      z-index: 3;
    }
  
    .nav-list.open {
      left: 0; 
    }
  
    .hamburger.active {
      transform: rotate(90deg); 
    }
  
    .nav-list li {
      margin: 10px 0; 
    }
  }
  