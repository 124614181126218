@import url("https://fonts.googleapis.com/css2?family=Dosis:wght@200;600;800&display=swap");

.cardContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5vh;
}

.lotteryCard {
  background: linear-gradient(
    to bottom,
    rgb(220, 92, 5, 0.7),
    rgb(255, 153, 0, 0.7)
  );
  border: 2px solid #ff9900;
  text-align: center;
  padding: 10px;
  max-width: 400px;
}

.cardHeader {
  color: white;
  background: transparent;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}

.winnerTitle {
  color: #ffffff;
  font-size: 36px;
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
}

.winnerNumber {
  color: #ffffff;
  font-size: 48px;
  text-align: center;
 
}

.dateFooter {
  color: #fff;
  font-size: 14px;
  font-family: 'Dosis', sans-serif;
  font-weight: 600;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
.banner {
  width: 100%;
  height: 600px;
  animation: banner 20s infinite linear alternate;
  background-size: 100% 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
@keyframes banner {
  0%, 20% {
    background-image: url("../img/backgroundbanner1.jpg");
  }
  20%, 40% {
    background-image: url("../img/backgroundbanner2.jpg");
  }
  40%, 60% {
    background-image: url("../img/backgroundbanner3.jpg");
  }
  60%, 80% {
    background-image: url("../img/backgroundbanner4.jpg");
  }
  100% {
    background-image: url("../img/backgroundbanner1.jpg");
  }
}


.capa {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.6);
}
.info {
  z-index: 1;
  width: 100%;
  max-width: 550px;
  text-align: center;
}
.info h1 {
  color: #fff;
  font-size: 45px;
  margin-bottom: 3vh;
  letter-spacing: 1px;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
}
