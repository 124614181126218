@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;1,200;1,400&family=Ysabeau+SC:wght@100;300&display=swap');

.containers {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .img-containers {
    position: relative;
    flex: 1;
  }
  
  .img {
    width: 100%;
    filter:  blur(3px);
   
    height: 50%;
  }
  
  .text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:flex-start;
    background: rgba(0, 0, 0, 0.5); /* Fondo semitransparente para el texto */
    color: white; /* Color del texto */
    padding: 10px;
    box-sizing: border-box;
    
   
    font-family: 'Nunito', sans-serif;
    font-size: 80px;
  }
  .small-text{
    font-size: 10px;
  }

  .component {
    flex: 1;
    text-align: right;
    padding: 10px;
  }
  
  @media screen and (max-width: 768px){
    .text-overlay{
      font-size: 20px;
    }
  }

  .gr{
    display: grid;
    gap: 20px;
    margin-bottom: 20px;
  
   
    padding: 20px;
}
.col-4 { grid-template-columns: repeat(4, 1fr)}
.scroll-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ff8800;
  color: #fff;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  font-size: 24px;
  cursor: pointer;
}
